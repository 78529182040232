export const getTableFields = () => ({
  applyByNote: [
    {
      key: 'invoiceNumber',
      label: 'Invoice',
    },
    {
      key: 'transactionDate',
      label: 'Date Issued',
    },
    {
      key: 'amountWithVat',
      label: 'Invoiced',
    },
    {
      key: 'amountDue',
      label: 'Amount Due',
    },
    {
      key: 'applyMax',
      label: 'Amount With VAT To Credit (USD)',
    },
  ],
  applyByTransaction: [
    {
      key: 'invoiceNumber',
      label: 'Note ID',
    },
    {
      key: 'createdAt',
      label: 'Created Date',
    },
    {
      key: 'amountDue',
      label: 'Amount Remaining',
    },
    {
      key: 'applyMax',
      label: 'Amount With VAT To Credit (USD)',
    },
  ],
})

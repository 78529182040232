<template>
  <div>
    <!-- Header -->
    <div class="d-flex justify-content-between apply-note_header">
      <div
        class="w-50 d-flex justify-content-center align-items-start flex-column"
      >
        <div class="d-flex flex-column col-sm-12 col-lg-6 text-start">
          <div>
            <span class="poppins-700-24 header-text text-secondary">Apply Credit Note</span>
          </div>
          <div
            v-if="shipmentSeqId"
            class="text-nowrap"
          >
            <span class="kp-text-color">Shipment ID:</span>
            <span class="kp-text-color ms-1">{{ shipmentSeqId }}</span>
          </div>
        </div>
      </div>
      <!-- Close icon -->
      <div class="w-25 d-flex justify-content-end align-items-center">
        <div>
          <kingpin-icon
            icon="XIcon"
            size="14px"
            class="cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="apply-note_body">
      <div class="apply-note_table_content table-responsive">
        <div
          v-if="isLoading"
          class="spinner-body"
        >
          <b-spinner />
        </div>
        <p-table
          v-else
          :fields="tableFields"
          :items="tableItems"
          show-empty
          empty-text="No data found"
        >
          <template #cell(invoiceNumber)="data">
            <b-link
              class="text-decoration-none text-nowrap"
              :to="{name: ROUTES.EDIT_NOTE.name, params : {id:data.item.noteId} }"
            >
              {{ data.item.invoiceNumber }}
            </b-link>
          </template>
          <template #cell(createdAt)="data">
            <div class="d-flex align-items-center">
              <kingpin-icon
                icon="AnalogClockIcon"
                size="16px"
                class="text-info me-1"
              />
              <span>{{ formattedDate(data.item.createdAt) }}</span>
            </div>
          </template>
          <template #cell(amountDue)="data">
            {{ formatCurrency(data.item.amountDue, data.item.currency) }}
          </template>
          <template #cell(applyMax)="data">
            <div class="row">
              <div class="col-sm-12 col-xxl-4 p-1">
                <k-form-input
                  type="number"
                  class="text-center"
                  size="sm"
                  :value="data.item.appliedAmount"
                  disabled
                />
              </div>
              <div class="col-sm-12 col-xxl-4 p-1">
                <k-button
                  variant="outline-secondary"
                  size="sm"
                  class="text-nowrap"
                  block
                  :disabled="!!data.item.appliedAmount || isApplying"
                  @click="onClickApplyMax(data.item)"
                >
                  APPLY MAX
                </k-button>
              </div>
            </div>
          </template>
        </p-table>
      </div>

      <!-- Total card -->
      <div
        v-if="!isItemsEmpty"
        class="w-100 p-1 mt-3 font-inter"
      >
        <div class="d-flex flex-column float-end total-container">
          <div class="d-flex flex-row mb-3">
            <div class="w-75 total-key-text text-start">
              Outstanding Amount Due:
            </div>
            <div class="w-25 total-value-text text-end">
              {{ formatCurrency(totalOutStandingDue, noteCurrency) }}
            </div>
          </div>
          <div class="d-flex flex-row subtotal-container pb-3">
            <div class="w-75 total-key-text text-start">
              Total Amount to Credit:
            </div>
            <div class="w-25 total-value-text text-end">
              <!-- As of now showing single amount - future we need to show sum of the applied ammount -->
              {{ formatCurrency(payload.appliedAmount || 0, noteCurrency) }}
            </div>
          </div>
          <div class="d-flex flex-row mt-3">
            <div class="w-75 total-key-text text-start">
              Amount Due:
            </div>
            <div class="w-25 total-value-text text-end text-info fw-bold">
              <!-- totalOutStandingDue - sum(appliedAmount) -->
              {{ formatCurrency((totalOutStandingDue - payload.appliedAmount) || 0, noteCurrency) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- action buttons -->
    <div
      v-if="!isItemsEmpty"
      class="w-100 d-flex flex-row justify-content-center apply-note_action_btns"
    >
      <k-button
        class="m-1"
        variant="info"
        :loading="isApplying"
        :disabled="isPayloadEmpty"
        @click="onClickApply"
      >
        APPLY CREDIT NOTE
      </k-button>
      <k-button
        class="m-1"
        variant="outline-medium"
        :disabled="isApplying"
        @click="$router.go(-1)"
      >
        CANCEL
      </k-button>
    </div>
  </div>
</template>

<script>
import {
  KingpinIcon, KButton, PTable, KFormInput,
} from '@kingpin-global/kingpin-ui'
import { APPLY_NOTE, GET_CN_TRANSACTIONS_BY_TRANSACTION } from '@/store/modules/notes.module'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { ROUTES } from '@/constants'
import { BLink } from 'bootstrap-vue'
import { getTableFields } from './apply-note-utils'

const { formatCurrency, formattedDate } = utils

export default {
  name: 'ApplyNoteByTransaction',
  components: {
    KingpinIcon,
    KButton,
    PTable,
    KFormInput,
    BLink,
  },
  data() {
    return {
      transactionObjId: '',
      shipmentSeqId: '',
      isLoading: false,
      totalOutStandingDue: 0,
      tableFields: getTableFields().applyByTransaction,
      tableItems: [],
      formattedDate,
      formatCurrency,
      noteCurrency: '',
      payload: {},
      isApplying: false,
      ROUTES,
    }
  },
  computed: {
    isItemsEmpty() {
      return !this.tableItems.length
    },
    isPayloadEmpty() {
      return !(Object.values(this.payload).length)
    },
  },
  created() {
    this.transactionObjId = this.$route.params.id
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.$store.dispatch(GET_CN_TRANSACTIONS_BY_TRANSACTION, this.transactionObjId)
        .then(res => {
          const data = res?.data?.data
          const items = data.creditApplicableCreditNotes
          this.totalOutStandingDue = data.totalOutStandingDue || 0
          this.shipmentSeqId = data.shipmentSeqId || ''
          if (items?.length) {
            this.noteCurrency = items[0].currency || ''
            this.tableItems = items.map(item => ({
              ...item,
              appliedAmount: 0,
            }))
          } else {
            this.tableItems = []
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.tableItems = []
        })
    },
    onClickApplyMax(item) {
      this.tableItems.forEach(tableItem => {
        if (tableItem._id === item._id) {
          tableItem.appliedAmount = tableItem.amountDue.toFixed(2)
        } else {
          tableItem.appliedAmount = 0
        }
      })
      this.payload = {
        creditNoteTransactionId: item._id,
        appliedAmount: item.amountDue,
      }
    },
    onClickApply() {
      this.isApplying = true
      this.$store.dispatch(APPLY_NOTE, { id: this.transactionObjId, payload: this.payload })
        .then(res => {
          this.isApplying = false
          apiToastSuccessV2(res.data.message)
          this.loadData()
          this.payload = {}
        })
        .catch(err => {
          this.isApplying = false
          apiToastErrorV2(err)
        })
    },
  },
}
</script>
